<template>
  <v-container fluid v-if="!isLoading">
    <v-row dense>
      <v-col cols="12" md="6">
        <v-text-field
          outlined
          clearable
          dense
          v-model="filtro"
          name="filtro"
          label="Filtrar por Especialidad / Categoría / Comentarios"
          :autocomplete="`new-${+new Date()}`"
        ></v-text-field>
      </v-col>
      <!-- botón para crear nuevo -->
      <v-col cols="12" md="6" v-if="!mostrarForm">
        <v-tooltip left color="success">
          <template v-slot:activator="{ on }">
            <v-btn
              outlined
              icon
              color="primary"
              class="float-right mr-1"
              v-on="on"
              @click="mostrarForm = !mostrarForm"
            >
              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
          </template>
          <span>Añadir Categoría</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row dense v-if="!mostrarForm">
      <v-col cols="12"> </v-col>
    </v-row>

    <!-- formulacio de alta y/o edicion de una especialidad -->
    <v-row justify="center" v-if="mostrarForm">
      <v-dialog v-model="mostrarForm" persistent>
        <div id="formulario">
          <v-expand-transition>
            <ficha-esp-form
              :datos="editData"
              :reco_id="reco_id"
              :categorias="categorias"
              :subcategorias="subcategorias"
              :listaEspecialidadesMulti="compEspecialidadesForm"
              @emitCancelar="mostrarForm = false"
            ></ficha-esp-form>
          </v-expand-transition>
        </div>
      </v-dialog>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <v-row dense>
      <!--    <masonry
        ref="my-masonry"
        :cols="{ default: 1, 1500: 1 }"
        :gutter="{ default: '15px', 700: '15px' }"
        class="px-6"
      > -->
      <v-container
        fluid
        class="pa-0 ma-0"
        v-for="dato in datosFiltrados"
        :key="dato.resp_id"
      >
        <v-card outlined class="mt-2 elevation-2" :min-width="707">
          <v-card-title class="subtitle-1 font-weight-medium">
            <v-container fluid class="pa-0 ma-0">
              <span class="purple--text">{{ dato[0].categoria }}</span> <br />
              <span>{{ dato[0].subcategoria }}</span>

              <v-tooltip left color="orange">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    icon
                    color="red"
                    v-on="on"
                    class="float-right"
                    @click="llamarModalEliminarEspCompleta(dato)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar Todo el Grupo</span>
              </v-tooltip>

              <v-tooltip left color="success">
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    icon
                    color="blue"
                    v-on="on"
                    class="float-right"
                    @click="nuevaSubesp(dato)"
                  >
                    <v-icon>mdi-bookmark-plus</v-icon>
                  </v-btn>
                </template>
                <span>Nuevo Comentario</span>
              </v-tooltip>
            </v-container>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row v-for="d in dato" :key="d.resp_id" dense>
              <v-col
                cols="12"
                @mouseenter="cogerFoco(d.resp_id)"
                @mouseleave="eliminarFoco()"
                :class="{ 'blue lighten-5': foco === d.resp_id }"
              >
                <div width="100%">
                  <!-- fecha -->
                  <div style="width: 90px" class="float-left">
                    <v-chip
                      :outlined="foco === d.resp_id"
                      color="primary"
                      small
                      label
                    >
                      {{ d.resp_date | fFecha }}
                    </v-chip>
                  </div>
                  <!-- comentario -->
                  <div
                    style="width: 80%"
                    class="float-left"
                    :class="{ 'blue lighten-5': foco === d.resp_id }"
                  >
                    <span @dblclick="editarSubc(d)"
                      class="ml-1"
                      v-html="comentarioMultilinea(d.resp_comments)"
                    >
                    </span>
                    <!-- agrupación -->
                    <span v-if="reco_agrup" @dblclick="editarSubc(d)">
                      <v-tooltip top color="pink">
                        <template v-slot:activator="{ on }">
                          <v-chip
                            v-on="on"
                            v-if="d.resp_agrup > 0"
                            class="ma-0 pa-0 mt-1 ml-1 float-left"
                            color="pink"
                            label
                            text-color="white"
                            x-small
                          >
                            <v-icon class="">
                              mdi-numeric-{{ d.resp_agrup }}
                            </v-icon>
                          </v-chip>
                        </template>
                        <span
                          >Comentario agrupado en el grupo
                          {{ d.resp_agrup }}</span
                        >
                      </v-tooltip>
                    </span>
                  </div>

                  <div
                    class="float-right pl-0 ml-0"
                    v-if="foco === d.resp_id"
                    :class="{ 'blue lighten-5': foco === d.resp_id }"
                  >
                    <!-- eliminar un comentario sin agrupar -->
                    <v-btn
                      v-if="d.resp_agrup === 0"
                      x-small
                      icon
                      color="red"
                      class="float-right"
                      @click="eliminarComentario(d)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <!--eliminar un comentario de una agrupacion -->
                    <v-btn
                      v-else
                      x-small
                      icon
                      color="purple"
                      class="float-right"
                      @click="llamarModalEliminarGrupoComentarios(d)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>

                    <!-- boton editar -->

                    <v-btn
                      x-small
                      icon
                      color="primary"
                      class="float-right"
                      @click="editarSubc(d)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
      <!--   </masonry> -->
    </v-row>
    <!-- dialogo para eliminar un comentario -->
    <dialog-eliminar
      v-if="dialogEliminar.mostrar"
      :mostrar="dialogEliminar.mostrar"
      @emitAceptar="eliminarSubc"
      @emitCancelar="cancelarModal"
      :cabecera="dialogEliminar.cabecera"
      :cuerpo="dialogEliminar.cuerpo"
    ></dialog-eliminar>
    <!-- dialogo eliminar toda la tarjeta esp/cat/comentarios -->
    <dialog-eliminar
      v-if="dialogEliminarCat.mostrar"
      :mostrar="dialogEliminarCat.mostrar"
      @emitAceptar="deleteEspCompleta"
      @emitCancelar="cancelarModal"
      :cabecera="dialogEliminarCat.cabecera"
      :cuerpo="dialogEliminarCat.cuerpo"
    ></dialog-eliminar>
    <!-- dialogo eliminar comentarios agrupados -->
    <dialog-eliminar-multic
      v-if="dialogEliminarMulti.mostrar"
      :mostrar="dialogEliminarMulti.mostrar"
      @emitAceptarMulti="eliminarTodosComentariosMulti"
      @emitAceptar="eliminarComentarioMulti"
      @emitCancelar="cancelarModal"
      :cabecera="dialogEliminarMulti.cabecera"
      :cuerpo="dialogEliminarMulti.cuerpo"
      :maxWidth="700"
    ></dialog-eliminar-multic>
  </v-container>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import VueMasonry from "vue-masonry-css";
import { mapActions, mapMutations } from "vuex";
import FichaEspForm from "@/components/fichas/parts/forms/FichaEspForm";
import DialogEliminar from "@/components/comun/Dialog";
import DialogEliminarMultic from "@/components/comun/DialogMulti";
import { recordsService } from "@/_services/records.service";

Vue.use(VueMasonry);

export default {
  name: "ficha-detalle-especialidades",
  props: ["datos", "categorias", "subcategorias", "reco_id", "reco_agrup"],
  components: {
    FichaEspForm,
    DialogEliminar,
    DialogEliminarMultic,
  },
  data: () => ({
    colsDefault: { default: 3, 1625: 2, 1500: 1 },
    isLoading: true,
    mostrarForm: false,
    editData: null,
    foco: null,
    datosAgrupados: [],
    datosSinAgrupar: [],
    filtro: null,
    dialogEliminar: {
      mostrar: false,
      cabecera: null,
      cuerpo: null,
      id: null,
    },
    dialogEliminarCat: {
      mostrar: false,
      cabecera: null,
      cuerpo: null,
      id: null,
    },
    dialogEliminarMulti: {
      mostrar: false,
      cabecera: null,
      cuerpo: null,
      id: null,
    },
  }),
  methods: {
    ...mapActions("records", ["setSpecialtiesDelete"]),
    ...mapMutations("records", ["SET_RECORDS_RELOAD"]),
    /* funcion para adaptar el texto de un comentario multilinea */
    comentarioMultilinea(comentario) {
      return comentario.trim().replaceAll("\n", "<br>&nbsp;");
    },
    /* limpiar los modales */
    cancelarModal() {
      this.dialogEliminar = {
        mostrar: false,
        cabecera: null,
        cuerpo: null,
        id: null,
      };
      this.dialogEliminarCat = {
        mostrar: false,
        cabecera: null,
        cuerpo: null,
        id: null,
      };
      this.dialogEliminarMulti = {
        mostrar: false,
        cabecera: null,
        cuerpo: null,
        id: null,
      };
    },
    /* editar subcategoria */
    editarSubc(item) {
      // console.log(item);
      this.editData = item;
      this.mostrarForm = true;
      this.$vuetify.goTo("#formulario");
    },
    /* eliminar un comentario - llamada al modal */
    eliminarComentario(item) {
      this.dialogEliminar.cabecera = "AVISO";
      this.dialogEliminar.cuerpo =
        "Desea eliminar este registro?<br/>Esta operación no se podrá revertir";
      this.dialogEliminar.id = item;
      this.dialogEliminar.mostrar = true;
    },
    /* eliminar un grupo */
    async eliminarSubc() {
      const item = this.dialogEliminar.id;
      // const res = await this.setSpecialtiesDelete(item.resp_id);
      const res = await recordsService.recordSpecDelete(item.resp_id);
      console.log("eliminarSubc", res);
      this.cancelarModal();
      this.SET_RECORDS_RELOAD(true);
      this.$emit("emitActualizarEsp", res.data);
    },
    /* funcion para eliminar un bloque entero */
    async deleteEspCompleta() {
      try {
        const dato = this.dialogEliminarCat.id[0];
        const data = {
          id_record: dato.resp_idrecord,
          id_category: dato.resp_idcategory,
          id_subcategory: dato.resp_idsubcategory,
        };
        delete this.datosAgrupados[data.id_subcategory];

        const res = await recordsService.delRecordSpecialties(data);
        this.SET_RECORDS_RELOAD(true);
        this.$emit("emitActualizarDatos", null);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
      this.cancelarModal();
    },

    /* funcion que llama desde el boton papelera en una tarjeta */
    llamarModalEliminarEspCompleta(item) {
      this.dialogEliminarCat.cabecera = "AVISO";
      this.dialogEliminarCat.cuerpo =
        "Desea eliminar esta Categoría/Especialidad y Comentarios?<br/>Esta operación no se podrá revertir";
      this.dialogEliminarCat.id = item;
      this.dialogEliminarCat.mostrar = true;
    },

    /* funcion para eliminar un solo comentario de una categoria multi */
    async eliminarComentarioMulti() {
      const item = this.dialogEliminarMulti.id;
      //  console.log("eliminar un comentario multi", item);

      //const res = await this.setSpecialtiesDelete(item.resp_id);
      const res = await recordsService.recordSpecDelete(item.resp_id);
      console.log(res);
      this.cancelarModal();
      this.SET_RECORDS_RELOAD(true);
      this.$emit("emitActualizarEsp", res.data);
    },

    /* eliminar COmentarios de una agrupacion multiple */
    async eliminarTodosComentariosMulti() {
      const item = this.dialogEliminarMulti.id;
      const data = {
        resp_idrecord: item.resp_idrecord,
        resp_agrup: item.resp_agrup,
      };

      // console.log("eliminar todos comentarios multi", data);
      try {
        const res = await recordsService.delGrupoComentarios(data);
        console.log(res);
        this.cancelarModal();
        this.SET_RECORDS_RELOAD(true);
        // llamamos al padre para que actualize datos
        this.$emit("emitActualizarEsp", res.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },

    /* funcion que llama al modal que elimina un grupo de comentarios o un comentario */
    llamarModalEliminarGrupoComentarios(item) {
      this.dialogEliminarMulti.cabecera = "AVISO  - COMENTARIO AGRUPADO";
      this.dialogEliminarMulti.cuerpo = `<b>ESTA OPCIÓN NO SE PUEDE REVERTIR</b><br/>
      Puede eliminar este comentario pulsando "<b>ELIMINAR ESTE COMENTARIO</b>".<br/>
      Puede eliminar el grupo completo pulsando "<b>ELIMINAR TODOS COMENTARIOS GRUPO</b>"`;

      this.dialogEliminarMulti.id = item;
      this.dialogEliminarMulti.mostrar = true;
    },

    /* funcion paracrear una nueva subespecialidad en la ficha */
    nuevaSubesp(dato) {
      // console.log(dato)
      const tmp = dato[0];

      const tipo = {
        nuevaSubesp: true,
        resp_id: null,
        resp_idrecord: null,
        resp_idcategory: parseInt(tmp.resp_idcategory),
        resp_idsubcategory: parseInt(tmp.resp_idsubcategory),
        resp_comments: null,
        resp_date: null,
        resp_idstatus: null, // a 0 los eliminados??
      };
      this.editData = tipo;
      this.mostrarForm = true;
    },

    cogerFoco(id) {
      // console.log("entro a coger el foco", id);
      this.foco = id;
    },

    eliminarFoco() {
      this.foco = null;
    },

    /* funcion para generar los datos de manera agrupada */
    generarDatosAgrupados() {
      if (this.datos === null) {
        this.datosAgrupados = [];
      }
      let data = { ...this.datos };

      data = _.each(data, (d) => {
        d.categoria = this.categorias.find(
          (c) => c.cate_id === parseInt(d.resp_idcategory)
        ).cate_name;
        d.subcategoria = this.subcategorias.find(
          (s) => s.subc_id === parseInt(d.resp_idsubcategory)
        ).subc_name;
      });
      this.datosSinAgrupar = this.datos;
      data = _.groupBy(this.datos, "resp_idsubcategory");
      data = _.sortBy(data, [ x => x[0].categoria, x => x[0].subcategoria ]);
      // console.log(Object.keys(data).length);
      this.datosAgrupados = data;
      this.isLoading = false;
    },
    recalcularMasonry(contador) {
      if (contador > 4) {
        this.colsDefault = { default: 3, 1610: 2, 1080: 1 };
      } else {
        this.colsDefault = { default: 1 };
      }
    },
  },
  computed: {
    /* funcion computada para listar todas las categorias/especialidades para agregar 1 comentario a multiples categorias */
    compEspecialidadesForm() {
      if (!this.datosSinAgrupar) {
        return [];
      }
      let sal = [...this.datosSinAgrupar];
      const res = _.uniqBy(sal, "resp_idsubcategory");

      return res;
    },
    /* funcion para listar los datos cuando hay un filtro */
    datosFiltrados() {
      if (this.filtro === null || this.filtro === "") {
        this.recalcularMasonry(Object.keys(this.datosAgrupados).length);
        return this.datosAgrupados;
      }

      const salida = this.datosSinAgrupar.filter((d) => {
        const cat = preparaStr(d.categoria);
        const sub = preparaStr(d.subcategoria);
        const comment = preparaStr(d.resp_comments);
        const tFiltro = preparaStr(this.filtro);

        if (
          compararRegexStr(tFiltro, cat) ||
          compararRegexStr(tFiltro, sub) ||
          compararRegexStr(tFiltro, comment)
        ) {
          // d.resp_comments = comment.replace(tFiltro, '<span color="yellow">' + filtro + '</span>');
          return d;
        }
      });

      const tmp = _.groupBy(salida, "resp_idsubcategory");

      this.recalcularMasonry(Object.keys(tmp).length);
      return tmp;
    },
  },
  filters: {
    fCategoria: function (value, categorias) {
      return categorias.find((c) => c.cate_id === parseInt(value)).cate_name;
    },
    fFecha: function (value) {
      return moment(value, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    fLineas: function (value) {
      return value.replace(/\n/, "<br/>");
    },
  },
  watch: {
    mostrarForm(newValue, oldValue) {
      if (newValue === false) {
        this.editData = null;
      }
    },

    datos: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        setTimeout(() => {
          this.generarDatosAgrupados();
        }, 250);
      },
    },
  },
  mounted() {
    this.generarDatosAgrupados();
  },
};

const preparaStr = (str, filtro) => {
  str = str.toLowerCase();

  str = str.replace(/[á]/, "a");
  str = str.replace(/[é]/, "e");
  str = str.replace(/[í]/, "i");
  str = str.replace(/[ó]/, "o");
  str = str.replace(/[ú]/, "u");

  return str;
};

const compararRegexStr = (filtro, str) => {
  let pattern = str.toLowerCase();
  pattern = pattern.replace(/[á]/, "a");
  pattern = pattern.replace(/[é]/, "e");
  pattern = pattern.replace(/[í]/, "i");
  pattern = pattern.replace(/[ó]/, "o");
  pattern = pattern.replace(/[ú]/, "u");

  let _value_ = filtro.toLowerCase();
  _value_ = _value_.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  _value_ = new RegExp(_value_, "gi");

  const salida = pattern.match(_value_);
  if (salida !== null) {
    return salida;
  }
};
</script>

<style scoped></style>
